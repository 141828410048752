import { EnumApi, EnumModel, EnumType, Tag, TagApi, LanguagePublicModel } from "@backend/api/pmToolApi";
import BaseResponse from "@models/BaseResponse";
import { isNumber } from "lodash";
import { TagTypeEnum } from "@models/filter/TagTypeEnum";
import DomainModel from "@models/domain/domainModel";
import DomainCachedApi from "@backend/store/apiCache/DomainCachedApi";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";

const GlobalStoreKeys = {
   domain: "_domain",
   entityHeaderExpanded: "_entityHeaderExpanded",
   language: "_language",
   hideContentBrickTreeNodes: "_hideContentBrickTreeNodes",
   showManuallyHiddenTreeNodes: "_showManuallyHiddenTreeNodes",
   showOnlyDglTreeNodes: "_showOnlyDglTreeNodes",
   showFullTree: "_showFullTree",
};

// TODO: need to refresh this "caches"

const enums: Map<EnumType, EnumModel[]> = new Map<EnumType, EnumModel[]>();
const tagsAll: Tag[] = [];
const tags: Map<TagTypeEnum, Tag[]> = new Map<TagTypeEnum, Tag[]>();

async function initTags() {
   if (!tagsAll) {
      try {
         let allTags = await TagApi.getAllTags();
         console.log("API Tag get all tags", tags);

         allTags.forEach((t) => {
            tagsAll.push(t);
         });

         tags.set(
            TagTypeEnum.SearchIdentifier,
            allTags.filter((t) => t.tagType == "Search Identifier")
         );
         tags.set(
            TagTypeEnum.Tag,
            allTags.filter((t) => t.tagType == "Tag")
         );
      } catch (error) {
         console.log("API Tag get all tags error", error);
         EventBus.$emit(Events.DisplayToast, {
            color: "error",
            text: `Failed to load tags`,
         });
      }
   }
}

const globalStore = {
   _domain: -1,
   _entityHeaderExpanded: null as boolean | null,
   _hideDataModelTreeContentBricks: null as boolean | null,
   _showManuallyHiddenTreeNodes: null as boolean | null,
   _showOnlyDglTreeNodes: null as boolean | null,
   _showFullTree: null as boolean | null,

   getDomain: function (): number {
      if (this._domain < 0) {
         const value = parseInt(localStorage.getItem(GlobalStoreKeys.domain));
         this._domain = isNumber(value) ? value : 0;
      }
      return this._domain;
   },
   setDomain: function (domain: number) {
      this._domain = domain;
      return localStorage.setItem(GlobalStoreKeys.domain, domain.toString());
   },
   getDomains: async function (): Promise<DomainModel[]> {
      let result: DomainModel[] = [];
      try {
         var domains = await DomainCachedApi.getDomains();
         if (result) {
            var sortedDomains = domains.sort((a, b) => {
               if (a.name === undefined) {
                  return 1;
               } else if (b.name === undefined) {
                  return -1;
               }
               return a.name.localeCompare(b.name);
            });
            result = sortedDomains.map((d) => ({
               id: d.domainId,
               name: d.name,
               displayText: d.name,
            }));
         }
      } catch (error) {
         console.log(`API get Domains error`, error);
      }
      return result;
   },
   getEntityHeaderExpanded: function (): boolean {
      if (this._entityHeaderExpanded === null) {
         const cachedValue = localStorage.getItem(GlobalStoreKeys.entityHeaderExpanded);
         const value = cachedValue !== null ? JSON.parse(cachedValue) : null;
         this._entityHeaderExpanded = value !== null ? value : true;
      }
      return this._entityHeaderExpanded ?? true;
   },
   setEntityHeaderExpanded: function (value: boolean) {
      this._entityHeaderExpanded = value;
      return localStorage.setItem(GlobalStoreKeys.entityHeaderExpanded, this._entityHeaderExpanded.toString());
   },
   getHideDataModelTreeContentBricks: function (): boolean {
      if (this._hideDataModelTreeContentBricks === null) {
         const cachedValue = localStorage.getItem(GlobalStoreKeys.hideContentBrickTreeNodes);
         const value = cachedValue !== null ? JSON.parse(cachedValue) : null;
         this._hideDataModelTreeContentBricks = value !== null ? value : false;
      }
      return this._hideDataModelTreeContentBricks ?? false;
   },
   setHideDataModelTreeContentBricks: function (value: boolean) {
      this._hideDataModelTreeContentBricks = value;
      return localStorage.setItem(
         GlobalStoreKeys.hideContentBrickTreeNodes,
         this._hideDataModelTreeContentBricks.toString()
      );
   },
   getShowManuallyHiddenTreeNodes: function (): boolean {
      if (this._showManuallyHiddenTreeNodes === null) {
         const cachedValue = localStorage.getItem(GlobalStoreKeys.showManuallyHiddenTreeNodes);
         const value = cachedValue !== null ? JSON.parse(cachedValue) : null;
         this._showManuallyHiddenTreeNodes = value !== null ? value : false;
      }
      return this._showManuallyHiddenTreeNodes ?? false;
   },
   setShowManuallyHiddenTreeNodes: function (value: boolean) {
      this._showManuallyHiddenTreeNodes = value;
      return localStorage.setItem(
         GlobalStoreKeys.showManuallyHiddenTreeNodes,
         this._showManuallyHiddenTreeNodes.toString()
      );
   },
   getShowOnlyDglTreeNodes: function (): boolean {
      if (this._showOnlyDglTreeNodes === null) {
         const cachedValue = localStorage.getItem(GlobalStoreKeys.showOnlyDglTreeNodes);
         const value = cachedValue !== null ? JSON.parse(cachedValue) : null;
         this._showOnlyDglTreeNodes = value !== null ? value : false;
      }
      return this._showOnlyDglTreeNodes ?? false;
   },
   setShowOnlyDglTreeNodes: function (value: boolean) {
      this._showOnlyDglTreeNodes = value;
      return localStorage.setItem(GlobalStoreKeys.showOnlyDglTreeNodes, this._showOnlyDglTreeNodes.toString());
   },
   getShowFullTree: function (): boolean {
      if (this._showFullTree === null) {
         const cachedValue = localStorage.getItem(GlobalStoreKeys.showFullTree);
         const value = cachedValue !== null ? JSON.parse(cachedValue) : null;
         this._showFullTree = value !== null ? value : false;
      }
      return this._showFullTree ?? false;
   },
   setShowFullTree: function (value: boolean) {
      this._showFullTree = value;
      return localStorage.setItem(GlobalStoreKeys.showFullTree, this._showFullTree.toString());
   },
   getEnum: async function (enumType: EnumType): Promise<EnumModel[]> {
      if (!enums.has(enumType)) {
         try {
            let enumModel: EnumModel[] = await EnumApi.getEnum(enumType);
            console.log(`API Enum get enum:'${enumType}'`, enumModel);
            enums.set(enumType, enumModel);
         } catch (error) {
            console.log(`API Enum get enum:'${enumType}' error`, error);
            EventBus.$emit(Events.DisplayToast, {
               color: "error",
               text: `Failed to load enum`,
            });
         }
      }

      var enumObject = enums.get(enumType);
      if (!enumObject) {
         throw `Could not get enum '${enumType}' from cache`;
      }
      return enumObject;
   },
   getAllTags: async function () {
      if (!tagsAll) {
         initTags();
      }

      return tagsAll;
   },
   getTags: async function (tagType: TagTypeEnum) {
      if (!tagsAll) {
         initTags();
      }

      return tags.get(tagType);
   },

   setLanguage: function (language: LanguagePublicModel) {
      localStorage.setItem(GlobalStoreKeys.language, JSON.stringify(language));
   },

   getLanguage: function (): LanguagePublicModel {
      var lang = localStorage.getItem(GlobalStoreKeys.language);
      return !lang ? null : JSON.parse(lang);
   },
};

export default globalStore;
