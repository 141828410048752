<template>
   <v-dialog v-model="showDialog" width="800" persistent>
      <v-card>
         <v-card-title primary-title>
            <span>Save new version</span>
            <v-spacer></v-spacer>
            <v-btn class="red--text" icon @click="clickClose">
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <v-form @submit="submit" onSubmit="return false;" ref="detailForm">
               <v-row>
                  <v-col>
                     <v-text-field v-model="versionNote" label="Version note" :counter="200" autofocus></v-text-field>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" text @click="addVersionNote">Save</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

export class VersionNoteDialogResult {
   result: boolean = false;
   versionNote: string = "";
}

@Component({
   name: "VersionNoteDialog",
   components: {},
})
export default class VersionNoteDialog extends Vue {
   versionNote: string = "";
   showDialog: boolean = false;
   resolve: (result: VersionNoteDialogResult) => void;

   @Watch("showDialog", { deep: true })
   onShown(): void {
      if (this.showDialog) {
         this.$nextTick(() => {
            this.$refs.detailForm.resetValidation();
         });
      }
   }

   async show(): Promise<VersionNoteDialogResult> {
      return new Promise<VersionNoteDialogResult>((resolve) => {
         this.versionNote = "";
         this.showDialog = true;
         this.resolve = resolve;
      });
   }

   submit() {
      this.addVersionNote();
   }

   get hasChanges(): boolean {
      return !!this.versionNote;
   }

   addVersionNote(): void {
      if (this.$refs.detailForm.validate()) {
         this.showDialog = false;
         this.resolve && this.resolve({ result: true, versionNote: this.versionNote });
      }
   }

   hideDetailDialog(): void {
      this.showDialog = false;
      this.resolve && this.resolve({ result: false, versionNote: "" });
   }

   clickClose() {
      this.hideDetailDialog();
   }
}
</script>
