<template>
   <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
         <v-btn icon v-bind="attrs" v-on="on" @click="goBack">
            <v-icon dense color="error">mdi-arrow-left</v-icon>
         </v-btn>
      </template>
      <span>{{ translateKey("backButton.backTooltip") }}</span>
   </v-tooltip>
</template>

<script lang="ts">
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import { RouteName } from "@root/routes";
import { Component, Prop } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";

@Component({
   name: "BackBtn",
   components: {},
})
export default class BackBtn extends ComponentBase {
   @Prop({ default: null })
   fallbackRoute: string;

   @Prop({ default: null })
   fallbackRouteName: RouteName;

   routeNames: string[] = ["back-button"];

   mounted() {
      this.loadRouteTranslations(this.routeNames);
      EventBus.$on(Events.LanguageChanged, () => this.loadRouteTranslations(this.routeNames));
   }

   goBack() {
      // if have valid location use default implementation
      if (this.$router.canGoBack) {
         this.$router.back();
      } else if (this.fallbackRoute) {
         //if reloaded/pasted url use fallback route
         this.$router.push({ path: this.fallbackRoute });
      } else if (this.fallbackRouteName) {
         //if reloaded/pasted url use fallback route
         this.$router.push({ name: this.fallbackRouteName });
      } else {
      } // has no fallback do nothing
   }
}
</script>
