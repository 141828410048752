<template>
   <v-card>
      <v-card-title>
         <v-row>
            <v-col cols="12" md="6" class="pt-0 pb-0" align-self="center">
               <span>{{ translatedLabel }}</span>
            </v-col>
            <v-col cols="12" md="6">
               <v-text-field
                  v-model="searchQuery"
                  append-icon="mdi-magnify"
                  :label="translateKey('label.search', translations)"
                  single-line
                  color="red darken-2"
                  hide-details
                  autofocus
               />
            </v-col>
         </v-row>
      </v-card-title>
      <v-card-text>
         <v-col>
            <v-data-table
               dense
               :search="searchQuery"
               :custom-filter="customRelatedEntitiesSearch"
               class="overview-table pmtool-table"
               :headers="relatedContentBricksHeaders"
               :items="relatedContentBricks"
               :loading="loading"
               :footer-props="footerProps"
            >
               <template v-slot:item.contentBrick.code="{ item }">
                  <router-link :to="getContentBrickDetailRouteValue(item.contentBrick)" class="black--text">
                     <span>{{ item.contentBrick.code }}</span>
                  </router-link>
               </template>
               <template v-slot:item.contentBrick.displayText="{ item }">
                  <router-link :to="getContentBrickDetailRouteValue(item.contentBrick)" class="black--text">
                     <span>{{ item.contentBrick.displayText }}</span>
                  </router-link>
               </template>
               <template v-slot:item.tags="{ item }">
                  <router-link v-for="tag in item.tags" :to="getTagDetailRouteValue(tag)" class="black--text">
                     <v-chip class="ml-1" small readonly="true">
                        {{ tag.displayText }}
                     </v-chip>
                  </router-link>
                  <router-link v-for="tag in item.relatedTags" :to="getTagDetailRouteValue(tag)" class="black--text">
                     <v-chip class="ml-1" small readonly="true">
                        {{ tag.displayText }}
                        <v-icon dense color="gray">mdi-link-variant</v-icon>
                     </v-chip>
                  </router-link>
               </template>
               <template v-slot:item.contentBrick.entityStatus="{ item }">
                  <lazy-component class="d-inline" rootMargin="200px">
                     <entity-status-picker
                        :status="item.contentBrick.entityStatus"
                        :showPointer="true"
                     ></entity-status-picker>
                     <placeholder slot="placeholder" width="170px" />
                  </lazy-component>
               </template>
            </v-data-table>
         </v-col>
      </v-card-text>
   </v-card>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import BaseResponse from "@models/BaseResponse";
import {
   ContentBrickDefinitionApi,
   ContentBrickDefinitionFilterOptions,
   ContentBrickDefinitionReference,
   IContentBrickDefinition,
   RelatedContentBrickDefinitionReference,
   TagReference,
   TranslationPublicModel,
   ContentBrickType,
   EntityStatus,
} from "@backend/api/pmToolApi";
import OverviewBase from "@components/Shared/Base/overview-base.vue";
import { DataOptions } from "vuetify";
import GlobalStore from "@backend/store/globalStore.ts";
import { ContentBrickTypeDecorator } from "@models/shared/ContentBrickTypeDecorator";
import EntityStatusPicker from "@components/Shared/entity-status-picker.vue";
import Placeholder from "@components/Shared/placeholder.vue";
import ContentBrickUtils from "@utils/ContentBrickUtils";

@Component({
   name: "ContentBrickDetailTabRelatedContentBricks",
   components: {
      EntityStatusPicker,
      Placeholder,
   },
})
export default class ContentBrickDetailTabRelatedContentBricks extends OverviewBase {
   searchQuery: string = "";
   relatedContentBricks: RelatedContentBrickDefinitionReference[] = [];

   @Prop({ required: true })
   contentBrick: IContentBrickDefinition | null;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   get translatedLabel(): string {
      return this.translateKey(
         ContentBrickUtils.relatedTabTranslationKeyMap.get(this.contentBrick?.type),
         this.translations
      );
   }

   // ---------- Base ----------
   loadData = this.loadRelatedContentBrickDefinitionReferences;
   protected defaultDataTableOptions: Partial<DataOptions> | undefined = {
      sortBy: ["code"],
      sortDesc: [true],
   };

   @Watch("translations")
   onTranslationsChanged() {
      this.translateHeaders(this.relatedContentBricksHeaders, this.translations);
   }

   // ---------- Filter ----------
   customRelatedEntitiesSearch(items: any, searchQuery: string, rowValue: RelatedContentBrickDefinitionReference) {
      if (searchQuery?.length) {
         var searchArray = searchQuery.toLowerCase().split(" ");
         for (var i = 0; i < searchArray.length; i++) {
            if (
               /* Search in CB definition */
               rowValue.contentBrick?.code?.toLocaleLowerCase().includes(searchArray[i]) ||
               rowValue.contentBrick?.displayText?.toLocaleLowerCase().includes(searchArray[i]) ||
               /* Search in Tags names */
               (rowValue.tags ?? []).some((v) => v && v.displayText?.toLowerCase().includes(searchArray[i])) ||
               /* Search in related Tags names */
               (rowValue.relatedTags ?? []).some((v) => v && v.displayText?.toLowerCase().includes(searchArray[i]))
            ) {
               return true;
            }
         }
         return false;
      }
      return true;
   }

   // ---------- Table ----------
   relatedContentBricksHeaders = [
      {
         text: "Code",
         value: "contentBrick.code",
         class: "pmtool-table-header-fixed-sm sticky-header",
         translationKey: "relatedContentBricksTab.codeHeader",
      },
      {
         text: "Name",
         value: "contentBrick.displayText",
         class: "sticky-header",
         translationKey: "relatedContentBricksTab.nameHeader",
      },
      {
         text: "Tags",
         value: "tags",
         class: "sticky-header",
         translationKey: "relatedContentBricksTab.tagsHeader",
         sortable: false,
      },
      {
         text: "Status",
         value: "contentBrick.entityStatus",
         class: "pmtool-table-header-fixed-md sticky-header",
         translationKey: "relatedContentBricksTab.statusHeader",
      },
   ];

   getContentBrickDetailRouteValue(item: ContentBrickDefinitionReference) {
      return {
         name: ContentBrickTypeDecorator.get(item.type).detailRouteName,
         params: { id: item.id },
      };
   }

   getTagDetailRouteValue(item: TagReference) {
      return {
         name: "tag-detail",
         params: { id: item.id },
      };
   }

   mounted() {
      this.loadData();
      this.translateHeaders(this.relatedContentBricksHeaders, this.translations);
   }

   // ---------- API ----------
   async loadRelatedContentBrickDefinitionReferences(): Promise<void> {
      if (!this.contentBrick?.tags?.length) {
         return;
      }

      this.loading = true;
      try {
         // prepare api call parameters
         const domain = GlobalStore.getDomain();

         let filter = new ContentBrickDefinitionFilterOptions({
            tags: this.contentBrick.tags,
            allowPartialTagMatch: true,
            searchIdentifiers: [],
            identifiers: undefined,
            entityStatus: undefined,
            lastOnlyAnyStatus: false,
            lastOnly: true,
            lastVisited: undefined,
            excludeEntityCodes: [this.contentBrick.code],
            entityCodes: undefined,
            type: this.contentBrick.type,
            entityStatuses: [EntityStatus.Active, EntityStatus.Draft],
            searchQuery: undefined,
            createdBy: undefined,
         });

         // Call the API
         let result = await ContentBrickDefinitionApi.getRelatedContentBrickDefinitionReferences(domain, filter);
         this.relatedContentBricks = result ?? [];
      } catch (error) {
         this.notifyError(error, "load", "Related ContentBrick definition references");
      } finally {
         this.loading = false;
      }
   }
}
</script>
