import { render, staticRenderFns } from "./app-root.vue?vue&type=template&id=59138b78&scoped=true"
import script from "./app-root.vue?vue&type=script&lang=ts"
export * from "./app-root.vue?vue&type=script&lang=ts"
import style1 from "./app-root.vue?vue&type=style&index=1&id=59138b78&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59138b78",
  null
  
)

export default component.exports