import { render, staticRenderFns } from "./lang-selector.vue?vue&type=template&id=04b00c60&scoped=true"
import script from "./lang-selector.vue?vue&type=script&lang=ts"
export * from "./lang-selector.vue?vue&type=script&lang=ts"
import style0 from "./lang-selector.vue?vue&type=style&index=0&id=04b00c60&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b00c60",
  null
  
)

export default component.exports