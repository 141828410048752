import ViewItem from "../models/view/ViewItem";
export default {
   getEnumItems(enumObject: { [s: number]: string }): ViewItem[] {
      var items: ViewItem[] = [] as ViewItem[];

      for (let item in enumObject) {
         var enumValue = Number(item);
         if (!isNaN(enumValue)) {
            var enumText = enumObject[enumValue];
            items.push({
               value: enumValue,
               text: this.splitCamelCaseWithAbbreviations(enumText),
            });
         }
      }

      return items;
   },

   splitCamelCaseWithAbbreviations(text: string): string {
      return text
         .split(/([A-Z][a-z]+)/)
         .filter((e) => {
            return e;
         })
         .join(" ");
   },
};
