<template>
   <v-dialog :value="showDialog" width="75%" persistent scrollable>
      <v-card>
         <v-card-title>
            <span>{{ title }}</span>
            <v-spacer />
            <v-btn
               class="red--text"
               icon
               v-shortkey.once="['esc']"
               @shortkey="hideEditorDialog()"
               @click="hideEditorDialog()"
            >
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <span v-if="subtitle">{{ subtitle }}</span>
            <vue-editor :disabled="readonly" v-model="vmodel" :class="editorClass"></vue-editor>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error mx-4" @click="hideEditorDialog">Ok</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { VueEditor } from "vue2-editor";

@Component({
   name: "RichTextAreaDialog",
   components: {
      VueEditor,
   },
})
export default class RichTextAreaDialog extends Vue {
   @VModel()
   vmodel: string | null;

   @Prop({ default: false })
   showDialog: boolean;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ required: true })
   title: string;

   @Prop({ default: undefined })
   subtitle: string | undefined;

   hideEditorDialog() {
      this.$emit("update:showDialog", false);
   }

   get editorClass() {
      return this.readonly ? ["vue-editor-disabled"] : [];
   }
}
</script>
<style scoped lang="scss">
/*hide quill toolbar when disabled */
::v-deep.vue-editor-disabled {
   .ql-toolbar {
      border-bottom: 0;
      > span {
         display: none;
      }
   }
}
</style>
